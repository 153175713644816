import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import IraDetails from 'wms/ira/IraDetails';

export default function IraDetailsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Router>
          <IraDetails path="/wms/iraDetails/:sessionId/:sessionCode/:statusOfSession" />
        </Router>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
